html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  box-sizing: border-box;
  background-color: transparent !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  width: auto;
  height: auto;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

*:not(svg),
*:not(svg)::after,
*:not(svg)::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

* {
  -webkit-font-smoothing: antialiased;
}

.conversation-link {
  color: var(--chakra-colors-text-interactive) !important;
  text-decoration: underline !important;
}
